<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/PictureSummary-UNGap-min.png"
          width="1200"
          height="578"
          alt=""
          title="UN Gap Test High Speed Video"
          class="img-responsive wp-image-12185"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="High-Speed Video" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. has the capability to obtain
            high-speed images of explosions showing the resultant metal
            morphology as the metal deforms and expands. Above are images
            showing a UN Gap test where the pipe explodes. The fracture
            locations and fracture propagation can be seen.
          </p>
          <p>
            SMS can provide such services for a wide variety of applications and
            sizes.
          </p>
        </Paragraph>
      </div>
    </div>
    <Paragraph></Paragraph>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Highspeed Image Explosive Assessment",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS can obtain high-speed images of explosive events to assess the metal morphology and explosion characteristics."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
